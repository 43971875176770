<template>
  <div>
    <v-dialog v-model="showDialog" max-width="80%" v-if="!isMobile">
      <v-card elevation="0" style="border-top-right-radius: 20px!important;border-top-left-radius: 20px!important;">
        <div class="bottom-modal-header-container">
          <div class="bottom-modal-header-content mt-4">
            {{ $t('termsTitle') }}
          </div>
        </div>
        <v-card-text class="background-gray ">
          <div style="background-color: #f7f7f7;" class="pa-2" :class="$vuetify.rtl ? 'text-right' : 'text-left'"
               dir="rtl">
            <div v-for="(section,n) in $vuetify.rtl ? sections : EnSections" :key="n" class="mb-3 mt-2">
              <h3 class="mb-2">{{ section.title }}</h3>
              <div style="color: #6d6d6d;" v-html="section.body">{{ section.body }}</div>
            </div>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>
    <template v-else>
      <v-fade-transition>
        <div class="overlay" v-show="showDialog" @click.self="closeModal" ref="overlay">
          <div class="bottom-modal" ref="bottomModal">
            <div class="bottom-modal-header-container">
              <!--            <div class="bottom-modal-header-handle" @click="closeModal"></div>-->
              <div class="bottom-modal-header-content">
                {{ $t('termsTitle') }}

              </div>
            </div>
            <div class="modal-content" style="text-align: right;">
              <div class="content-shell text-right" dir="rtl">
                <div v-for="(section,n) in $vuetify.rtl ? sections : EnSections" :key="n">
                  <h3>{{ section.title }}</h3>
                  <p class="mt-2" v-html="section.body"></p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </v-fade-transition>
    </template>
  </div>

</template>

<script>
/*eslint-disable*/

import gsap from 'gsap';

export default {
  methods: {
    closeModal() {
      if (this.isMobile) {
        gsap.to(this.$refs.bottomModal, .5, {
          bottom: '-80%',
          // onComplete: () => {
          //   this.$emit('modalClosed')
          //   this.showDialog = false;
          // }
        })
        setTimeout(() => {
          this.$emit('modalClosed')
          this.showDialog = false;
        }, 300)
      } else {

      }
    }
  },
  props: {
    showModal: {
      default: false
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  watch: {
    showDialog(val) {
      if (val) {
        if (this.isMobile) {
          // console.log(this.$refs.bottomModal)
          gsap.to(this.$refs.bottomModal, .5, {
            bottom: '0%'
          })
        }
      } else {
        if (!this.isMobile) {
          this.$emit('modalClosed')
        }
      }
    },
    showModal(val) {
      if (val) {
        this.showDialog = true;
      }
    }
  },
  data() {
    return {
      showDialog: false,
      sections: [
        {
          title: 'همراه عزیز سلام',
          body: `متشکریم که به سیمیاروم اعتماد کردید. خواهشمندیم پیش از رزرو جلسات، شرایط و ضوابط مربوط به مشاوره آنلاین در سیمیاروم را با دقت مطالعه کنید.`
        },
        {
          title: 'ثبت‌نام و عضویت',
          body: `توجه داشته باشید که با ثبت‌نام در سایت سیمیاروم، موافقت خود را با تمامی قوانین و مقررات این صفحه اعلام می‌کنید. سن مجاز: شما باید حداقل ۱۸ سال سن داشته باشید. استفاده از خدمات سیمیاروم برای افراد زیر ۱۸ سال تنها با رضایت والدین یا قیم قانونی مجاز است. تغییرات: سیمیاروم حق این را دارد که در هر زمان قوانین و مقررات خود را تغییر دهد؛ البته این موضوع از طریق ایمیل به شما اطلاع رسانی خواهد شد. انتقال حساب کاربری: انتقال حساب کاربری به دیگران مجاز نیست و همچنین داشتن چندین حساب کاربری نیز ممنوع است.`
        },
//         {
//           title: 'انتخاب مشاور و زمان مشاوره',
//           body: `برای انتخاب مناسب‌ترین مشاور، تیم تخصصی ما در جلسات پیش مشاوره، همراه شما خواهد بود. در این جلسه 15 دقیقه‌ای که با یکی از متخصصان ارزیابی و ارجاع خواهید داشت، متناسب با نوع مساله و ترجیحات شما، راهنمایی‌های لازم جهت انتخاب تراپیست یا مسیر درمانی صورت خواهد گرفت؛ اما در نهایت انتخاب و تصمیم نهایی با خود شماست.
// `
//         },
        {
          title: 'حریم شخصی',
          body: `<b>رعایت محرمانگی:</b>
           سیمیاروم متعهد به حفظ و رعایت محرمانگی تمامی اطلاعات شماست. اطلاعات شخصی شما به هیچ وجه با اشخاص ثالث به اشتراک گذاشته نخواهد شد مگر در مواردی که به موجب قوانین لازم‌الاجرا یا دستورات قضایی ملزم به افشای آن باشیم.
            <br></br>
           <b>ضبط جلسات:</b>
            به منظور حفظ حریم شخصی و اطمینان از امنیت مکالمات، سیمیاروم به هیچ عنوان جلسات مشاوره را ضبط نمی‌کند. تمامی مکالمات میان روان‌درمانگر و شما کاملاً محرمانه باقی می‌ماند.
            <br></br>

            <b>حفاظت از داده‌ها:</b>
            سیمیاروم از پیشرفته‌ترین روش‌های امنیتی برای حفاظت از داده‌های شما استفاده می‌کند. اطلاعات شما در سرورهای امن نگهداری شده و تنها به پرسنل مجاز، دسترسی داده می‌شود.
            <br></br>

            <b>استفاده از کوکی‌ها و سرویس‌های تحلیلی:</b>
             سیمیاروم ممکن است از کوکی‌ها و سرویس‌های تحلیلی نظیر Google Analytics استفاده کند. این سرویس‌ها تنها اطلاعات ناشناس مربوط به مرورگر و استفاده شما از سایت را جمع‌آوری می‌کنند و هیچ گونه اطلاعات شخصی مثل نام، اطلاعات تماس یا موضوع و محتوای جلسه به این سرویس‌ها منتقل نمی‌شود.
            <br></br>

            <b>مسئولیت اطلاعات امنیتی:</b>
             شما مسئول حفظ محرمانگی رمز عبور و نام کاربری (در صورت وجود) و هرگونه اطلاعات امنیتی دیگر مرتبط با حساب خود در تمام زمان‌ها هستید. سیمیاروم در قبال هرگونه خسارتی که به دلیل دسترسی و استفاده از حساب شما توسط شخص دیگری، چه با اطلاع و چه بدون اطلاع شما، متحمل می‌شوید، مسئول نخواهد بود.
            <br></br>

`
        },
        {
          title: 'استفاده از خدمات:',
          body: `
          <b>مشاوره آنلاین:</b>
           سیمیاروم خدمات مشاوره روانشناسی آنلاین ارائه می‌دهد. کاربران می‌توانند با مشاوران مجاز در پلتفرم تماس بگیرند و جلسات مشاوره برگزار کنند.
            <br><br>
            <b>
            تعیین وقت:
            </b>
             شما باید وقت جلسات مشاوره خود را از طریق سیستم رزرو آنلاین سایت تعیین کنید. لغو یا تغییر وقت باید حداقل ۲۴ ساعت قبل از زمان مقرر اعلام شود.
                         <br><br>
            <b>
رفتار مناسب:
            </b>
 شما موظف هستید که در جلسات مشاوره با مشاوران محترمانه رفتار کنید. هرگونه رفتار نامناسب یا توهین‌آمیز می‌تواند منجر به مسدود شدن حساب کاربری شود.
            <br><br>
            <b>
            شرایط فنی:
            </b>
            شما باید مطمئن شوید که تمام شرایط فنی لازم برای انجام روان‌درمانی آنلاین فراهم است و به ویژه باید دارای دستگاه پایدار شامل دوربین و میکروفون و اتصال اینترنت کافی باشید.
            <br>
            <br>
            <b>
            مدت زمان جلسات:
           </b>
مدت زمان هر جلسه ۴۵ دقیقه است و در صورت بیشتر شدن مدت زمان جلسه، هزینه اضافی بر اساس نرخ‌های مشخص شده محاسبه خواهد شد.
<br>
<br>
<b>
مشاور جایگزین:
</b>
 اگر مشاوری که با او ارتباط برقرار کرده‌اید در هر زمانی پس از ارتباط شما از پلتفرم استفاده نکند، به شما اطلاع می دهیم که مشاور شما دیگر در پلتفرم نیست و شما فرصتی برای تطابق با یک مشاور جدید خواهید داشت.

          `
        },
        {
          title: 'موارد اورژانسی:',
          body: `
           <b>محدودیت خدمات:</b>
           سیمیاروم یک پلتفرم آنلاین برای مشاوره روانشناسی است و خدمات ارائه شده از سوی این پلتفرم مناسب برای موارد اورژانسی نظیر خطر خودکشی، آسیب به خود یا دیگران و سایر بحران‌های روانی حاد نیست.
            <br><br>
            <b>پیشنهادات در موارد اورژانسی:</b>
             در صورت تجربه هرگونه بحران اورژانسی یا نیاز به کمک فوری، توصیه می‌شود که فوراً با شماره‌های اضطراری کشور محل اقامت خود تماس بگیرید یا به نزدیکترین مرکز درمانی مراجعه کنید.
            <br><br>
            <b>
            تعهدات:
            </b>
             سیمیاروم هیچگونه مسئولیتی در قبال استفاده از خدمات این پلتفرم در شرایط اورژانسی ندارد و شما می‌بایست در این موارد به خدمات مناسب و فوری مراجعه کنید.
            <br><br>
          `
        },
        {
          title: 'پرداخت‌ها: ',
          body: `
           <b>
           هزینه‌ها:
           </b>
           هزینه جلسات رواندرمانی طبق نرخ‌های مشخص شده در سایت سیمیاروم محاسبه می‌شود. هزینه هر جلسه باید قبل از برگزاری جلسه پرداخت شود.
           <br><br>
            <b>
            بازگشت وجه:
            </b>
            در صورت لغو جلسه توسط شما تا ۲۴ ساعت قبل از زمان مقرر، هزینه پرداختی بازگردانده می‌شود. در صورت لغو جلسه پس از این مدت، هزینه پرداختی بازگردانده نخواهد شد.
            <br><br>
            <b>روش‌های پرداخت:</b>
            می‌توانید از روش‌های پرداخت آنلاین موجود در سایت استفاده کنید. تمامی پرداخت‌ها از طریق درگاه‌های امن انجام می‌شود.
            <br/><br/>
            <b>
            نامه بیمه:
            </b>
             سیمیاروم نامه بیمه را فراهم می‌کند ولی مسئولیتی در رابطه با دریافت هزینه نخواهد داشت.
             <br><br>
          `
        },
        {
          title: 'مسئولیت‌ها:',
          body: `
          <b>
          محتوای مشاوره:
          </b>
          سیمیاروم مسئولیت محتوای جلسات مشاوره را بر عهده ندارد و تمام محتوای جلسات بین شما و روان‌درمانگر محرمانه خواهد بود، مگر در صورت شکایت یا نارضایتی شما و اعلام رضایت جهت بررسی پرونده.
            <br>
            <br>
            <b>
            استفاده نادرست:
            </b>
            سیمیاروم هیچگونه مسئولیتی در قبال استفاده نادرست از خدمات پلتفرم توسط کاربران ندارد. کاربران موظف‌اند از خدمات سیمیاروم به طور قانونی و منصفانه استفاده کنند.
            <b>
            قطعی سرویس:
            </b>
             سیمیاروم تلاش می‌کند تا خدمات خود را به صورت پیوسته و بدون وقفه ارائه دهد. اما در صورت بروز مشکلات فنی یا قطعی‌های ناخواسته، سیمیاروم مسئولیتی در قبال این موارد نخواهد داشت.
          `
        },
        {
          title: 'مالکیت معنوی:',
          body: `
          <b>
          محتوا و حقوق:
          </b>
          تمامی محتواها، مطالب، طراحی‌ها، لوگوها و علائم تجاری موجود در سایت سیمیاروم متعلق به این پلتفرم است و هرگونه استفاده بدون اجازه کتبی از مالکیت معنوی سیمیاروم، ممنوع است.
          <br><br>
          <b>
          حق تکثیر:
          </b>
          هیچ بخشی از محتواهای سایت سیمیاروم نمی‌تواند بدون اجازه کتبی از سیمیاروم تکثیر، توزیع، انتشار یا بازتولید شود.
            <br><br>

          `
        },
        {
          title: 'ارتباطات:',
          body: `
          <b>
          راه ارتباطی اصلی:
          </b>
           راه ارتباطی اصلی سیمیاروم با کاربران از طریق ایمیل است. زمان جلسات و اطلاع‌رسانی‌های مهم از طریق ایمیل به شما اطلاع داده خواهد شد. اگر هرگونه سوال یا ابهامی در مورد این شرایط دارید، لطفاً با ما تماس بگیرید.
          <br><br>
          <b>
          حق تکثیر:
          </b>
          هیچ بخشی از محتواهای سایت سیمیاروم نمی‌تواند بدون اجازه کتبی از سیمیاروم تکثیر، توزیع، انتشار یا بازتولید شود.
            <br><br>

          `
        },
        {
          title: 'با تشکر از شما برای انتخاب سیمیاروم.',
          body: `
        امیدواریم خدمات و تلاش‌ جمعی ما منجر به سلامت روان و آگاهی بیشتر در این زمینه باشد.

          <br/>
          <br/>
اطلاعات تماس: می‌توانید از طریق ایمیل info@simiaroom.com و واتس‌اپ به شماره
 <div style="direction: ltr !important;display:inline-block">
 <a href="tell:+989398278914">
 +989398278914
 </a>
</div>
 با پشتیبانی سیمیاروم در تماس باشید.
          <br>
          `
        },
      ],
      EnSections: [
        {
          "title": "Dear Customer, Hello",
          "body": "Thank you for choosing Simiaroom and our psychologists and psychiatrists for your counseling sessions. Before reserving sessions, please carefully read the terms and conditions related to online counseling on Simiaroom's platform and then proceed with your reservation."
        },
        {
          "title": "Privacy Protection",
          "body": "All private information between the client and the therapist will remain confidential, and the therapist is ethically and legally obligated to protect this information unless it is believed that the client may harm themselves or others. In such cases, the psychologist can share this information with legal authorities. The therapist must ensure that there is a reasonable belief of potential harm to the client or others before taking such actions."
        },
        {
          "title": "Personal Information",
          "body": "To establish effective communication on this website, certain information such as the client's medical and psychological history, contact number, and a contact person in case of emergencies are required. All this information, and any other information provided by the client to the therapist, will be stored solely on this website and used solely to enhance the effectiveness of counseling sessions."
        },
        {
          "title": "Selecting a Counselor and Counseling Time",
          "body": "All Simiaroom counselors are experts in their respective fields and are selected based on your counseling needs and the therapist's specialization by the Simiaroom team. The counseling time will be communicated to you. If you have a preference for a specific therapist, you can inform Simiaroom experts after placing your order, and they will consider your choice while selecting a counselor for you. The process, from requesting a reservation to confirmation and scheduling of the counseling session, takes less than 24 hours."
        },
        {
          "title": "Recording of Sessions",
          "body": "All video and online sessions are completely private, and no recording is done through Simiaroom's website. Recording of sessions by clients or therapists is strictly prohibited."
        },
        {
          "title": "Emergencies",
          "body": "This website is not designed for critical issue resolution. In situations where the psychologist determines that the illness is severe, face-to-face visits and emergency services are recommended."
        },
        {
          "title": "Payment",
          "body": "All payments must be made through Simiaroom's secure international payment gateway. Simiaroom will not be responsible for any payments made through other means."
        },
        {
          "title": "Canceling or Rescheduling a Session",
          "body": "To reschedule a session to another time, you must inform the session coordination team at least 48 hours before the counseling session. Please note that the payment made is non-refundable, and this action only allows you to change the session time. Changing the session time is possible only twice (with prior notification of 48 hours). If the session is rescheduled within less than 48 hours to one hour before the scheduled time, your session will be canceled, and 50% of the payment will be deducted. If you inform about the rescheduling within less than one hour before the session, the session will be canceled, and no refund will be given. In case of any issues such as internet connectivity problems in Iran or difficulties faced by the counselor, the client can choose between a refund or rescheduling the counseling session to another time."
        },
        {
          "title": "Reporting Problems or Complaints",
          "body": "If you are not satisfied with the counseling process or have any complaints, you can communicate your concerns to Simiaroom's support team through the provided channels. They will handle your message promptly and take appropriate actions. These are some of the essential rules we wanted to share with you regarding online counseling. If you have more questions that have not been answered here, please refer to the FAQ page or contact Simiaroom experts through the available communication channels.\n\nThank you for your trust.\n\nSimiaroom"
        }
      ]
    }
  }
}
</script>

<style scoped>
.wizard-modal {

}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4;
  background-color: rgba(0, 0, 0, .3);
  overflow: hidden !important;

}

.bottom-modal {
  position: absolute !important;
  bottom: -80%;
  width: 100%;
  height: 80%;
  border-radius: 20px;
  overflow: hidden;
}

.bottom-modal-header-container {
  width: 100%;
  height: 90px;
  background-color: white;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  text-align: center;
  padding: 15px 0 0 0;
}

.content-shell {
  height: 80%;
  overflow-y: scroll;
}

.modal-content {
  height: 100%;
  padding: 10px 20px;
  line-height: 25px;
  font-size: .8em;
  background-color: #f7f7f7;
  color: #6d6d6d;
}

.bottom-modal-header-content {
  color: #4597df;
  font-size: 1.2em;
}

.bottom-modal-header-handle {
  width: 50px;
  height: 4px;
  background-color: #dbdbdb;
  border-radius: 3px;
  margin: 0 auto 10px auto;
  cursor: pointer;
}
</style>